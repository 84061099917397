<template>
  <div>
    <!-- 底部技术支持 -->
  
    <div class="all">
      <div class="progressContainer">
        <div class="dayContainer">
          <div class="dayleft">{{ stageList[0].cycleDay }}天</div>
          <div class="dayright">
            <div>{{ stageList[0].cycleDay + stageList[1].cycleDay }}天</div>
            <div>{{ stageList[0].cycleDay + stageList[1].cycleDay + stageList[2].cycleDay }}天</div>
          </div>
        </div>
        <div class="progressLineContainer">
          <div class="progress1" v-if="progressIndex == 0">
            <div class="progress1Line"></div>
            <div class="progress1Dot"></div>
          </div>
          <div class="progress2" v-if="progressIndex == 1">
            <div class="progress1Line"></div>
            <div class="progress1Dot"></div>
          </div>
          <div class="progress3" v-if="progressIndex == 2">
            <div class="progress1Line"></div>
            <div class="progress1Dot"></div>
          </div>
        </div>
        <div class="iconContainer">
          <div :class="progressIndex==0? 'icon1Select':'icon1'">育苗阶段</div>
          <div :class="progressIndex==1? 'icon2Select':'icon2'">坡养阶段</div>
          <div :class="progressIndex==2? 'icon3Select':'icon3'">育肥阶段</div>
        </div>
      </div>
      <div class="checkBackground">
        <img style="width: 100%;height: 100%;" src="https://asset.fsytss.com/trace/wcj/bg_state_chicken.png" alt="">
      </div>
      <!-- 具体信息 -->
      <div class="detailContainer">
        <!-- 主体1 -->
        <div class="imgcontainer" v-if="progressIndex == 0">
          <div class="imgleft" @click="changgeStep(2)">
            <img
              v-if="stageList[2].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[2].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[2].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_2.png"
              alt=""
            />
          </div>
          <div class="imgMid">
            <div class="goto1" @click="gotostep(2)">〈</div>
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_1.png"
              alt=""
            />
            <div class="goto2" @click="gotostep(1)">〉</div>
          </div>
          <div class="imgright" @click="changgeStep(1)">
            <img
              v-if="stageList[1].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[1].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[1].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_3.png"
              alt=""
            />
          </div>
        </div>
        <!-- 主体2 -->
        <div class="imgcontainer" v-if="progressIndex == 1">
          <div class="imgleft" @click="changgeStep(0)">
            <img
              v-if="stageList[0].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[0].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[0].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_1.png"
              alt=""
            />
          </div>
          <div class="imgMid">
            <div class="goto1" @click="gotostep(0)">〈</div>
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_2.png"
              alt=""
            />
            <div class="goto2" @click="gotostep(2)">〉</div>
          </div>
          <div class="imgright" @click="changgeStep(2)">
            <img
              v-if="stageList[2].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[2].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[2].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_3.png"
              alt=""
            />
          </div>
        </div>
        <!-- 主体3 -->
        <div class="imgcontainer" v-if="progressIndex == 2">
          <div class="imgleft" @click="changgeStep(1)">
            <img
              v-if="stageList[1].thumbnailImageUrl"
              :src="stageList[1].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[1].thumbnailImageUrl"
              src="https://asset.fsytss.com/trace/wcj/default_state_2.png"
              alt=""
            />
          </div>
          <div class="imgMid">
            <div class="goto1" @click="gotostep(1)">〈</div>
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              src="https://asset.fsytss.com/trace/wcj/default_state_3.png"
              alt=""
            />
            <div class="goto2" @click="gotostep(0)">〉</div>
          </div>
          <div class="imgright" @click="changgeStep(0)">
            <img
              v-if="stageList[0].thumbnailImageUrl"
              :src="stageList[0].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[0].thumbnailImageUrl"
              src="https://asset.fsytss.com/trace/wcj/default_state_1.png"
              alt=""
            />
          </div>
        </div>
        <div class="infocontainer">
          <div class="backgroundtRACE">
            <img src="https://asset.fsytss.com/trace/wcj/bg_trace_f2.png" alt="">
          </div>
          <div class="topcontainer">
            <div class="stepTagContainer">
              <img v-if="progressIndex==0" src="https://asset.fsytss.com/trace/wcj/icon_default_trace_f2_1.png" alt="" />
              <img v-if="progressIndex==1" src="https://asset.fsytss.com/trace/wcj/icon_default_trace_f2_3.png" alt="" />
              <img v-if="progressIndex==2" src="https://asset.fsytss.com/trace/wcj/icon_default_trace_f2_2.png" alt="" />
              <div  class="tagBackground">{{progressIndex==0?'育苗阶段':progressIndex==1?'坡养阶段':'育肥阶段'}}</div>
            </div>
            <div class="situation" @click="showDialog(stageList[progressIndex].id)">饲养情况</div>
          </div>
          <div class="infoitem">
            <div class="key">养殖天数</div>
            <div class="value">{{ stageList[progressIndex].cycleDay }}</div>
          </div>
          <div class="infoLine"></div>
          <div class="infoitem">
            <div class="key">
              养
              <div style="width: 6.5px" />
              殖
              <div style="width: 7px" />
              户
            </div>
            <div class="value">
              {{
                stageList[progressIndex].breedingFarmer
                  ? stageList[progressIndex].breedingFarmer
                  : "个体户"
              }}
            </div>
          </div>
          <div class="infoLine"></div>
          <div class="infoitem">
            <div class="key">
              养
              <div style="width: 6.5px" />
              殖
              <div style="width: 7px" />
              场
            </div>
            <div class="value" style="color: #d82a1f" @click="onClickFarm(stageList[progressIndex])">
              {{
                stageList[progressIndex].farmManagerName
                  ? stageList[progressIndex].farmManagerName
                  : "个体户"
              }}
            </div>
          </div>
          <div class="infoLine"></div>
          <div class="infoitem">
            <div class="key">
              圈
              <div style="width: 6.5px" />
              舍
              <div style="width: 7px" />
              码
            </div>
            <div class="value">
              {{
                stageList[progressIndex].farmManagerCircleHouse
                  ? stageList[progressIndex].farmManagerCircleHouse
                  : "个体户"
              }}
            </div>
          </div>
          <div class="infoLine"></div>
          <div class="infoitem">
            <div class="key">喂食饲料</div>
            <div class="value">{{ stageList[progressIndex].feedingName }}</div>
          </div>
          <div class="infoLine"></div>
          <div class="infoitem">
            <div class="key">疫苗接种</div>
            <div class="value">{{ stageList[progressIndex].vaccineName }}</div>
          </div>
          <div class="infoLine"></div>

          <div class="date">
            <div v-if="stageList[progressIndex].pushTime" class="dateText">入栏日期： {{ stageList[progressIndex].formatpushTime  }}</div>
            <div  v-if="stageList[progressIndex].popTime" class="dateText">出栏日期： {{ stageList[progressIndex].formatpopTime  }}</div>
          </div>
        </div>
      </div>
    
    </div>
    <van-dialog
      v-model="show"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
    >
      <div style="margin: 16px; font-weight: bold; text-align: center">
        饲养记录
      </div>
      <div
        style="
          height: 50vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
        "
      >
        <van-list
          v-model="loading"
          :finished="true"
          v-if="feedingRecordList.length > 0"
        >
          <div
            style="display: flex; width: 100%"
            v-for="(item, index) in feedingRecordList"
            :key="index"
          >
            <div
              style="
                flex: 1;
                border-bottom: 0.5px solid rgb(229, 229, 229);
                padding: 8px;
                margin: 4px 0;
              "
            >
              <div style="display: flex; color: #a1a1a1">
                <div
                  class="bgc"
                  style="
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 8px;
                    border-radius: 6px;
                    color: #d82a1f;
                    background-color: #f9edea;
                  "
                >
                  饲养日期
                </div>
                <div
                  style="
                    flex: 1;
                    color: #d82a1f;
                    font-weight: bold;
                    padding: 5px 0 5px 8px;
                    margin: auto 0;
                    text-align: right;
                  "
                >
                {{ getFeedingTime(item.feedingTime) }}至{{ getFeedingTime(item.feedingEndTime) }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  color: #a1a1a1;
                  margin-top: 10px;
                  justify-content: space-between;
                "
              >
                <div style="width: 150px; padding-left: 8px">投喂饲料</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ item.feedingFood }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #a1a1a1;
                  margin-top: 10px;
                "
              >
                <div style="padding-left: 8px">投喂频次</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ getFeedingFrequency(item.feedingFrequency) }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂未上传数据"
          style="height: 100%"
          v-if="feedingRecordList.length <= 0"
        />
      </div>
    </van-dialog>
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
     <!-- 养殖场弹出 -->
     <van-dialog
      v-model="showFarm"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
      style="background: #34ac40"
    >
      <div
        style="
          margin: 16px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
        "
      >
        {{ farm ? farm.name : "" }}
      </div>
      <div
        style="
          height: 55vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
          background: linear-gradient(360deg, #f4f6f8 0%, #34ac40 100%);
        "
      >
        <div class="box" style="margin-top: 0">
          <div style="display: flex">
            <img
              src="../../assets/images/bias-double-dot.png"
              style="width: 18px; height: 18px"
            />
            <div style="line-height: 18px; margin-left: 5px; font-weight: bold">
              基础信息
            </div>
          </div>

          <div style="flex: 1; color: #a1a1a1">
            <div style="display: flex; margin-top: 4px">
              养殖容量
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.volume }}
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              圈
              <div style="width: 7px" />
              舍
              <div style="width: 7px" />
              数
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.circleHouse }}个
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              面积(m²)
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.acreage }}
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-top: 8px">
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                养殖场简介
              </div>
            </div>
            <van-image
              width="100%"
              height="22vh"
              :src="farm ? farm.imageUrl : ''"
              style="margin-top: 8px"
            />
            <div
              style="
                color: #222222;
                font-size: 14px;
                margin-top: 8px;
                font-weight: normal;
              "
            >
              {{ farm.introduction }}
            </div>
          </div>
        </div>
        <div class="box">
          <div
            style="
              color: #222222;
              font-weight: bold;
              margin-top: 8px;
              margin-bottom: 12px;
            "
          >
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                定位信息
              </div>
            </div>
            <div
              id="farmMap"
              style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px"
            ></div>

            <div
              style="
                display: flex;
                color: #222222;
                font-weight: normal;
                margin-top: 8px;
              "
            >
              <div style="width: 42px">{{ "地址:" }}</div>
              <div style="flex: 1">{{ farm.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { Image as VanImage, Dialog, Empty, List, Loading } from "vant";
import { post } from "../../utils/http";
import moment from "moment";
Vue.use(VanImage).use(Dialog).use(Empty).use(List).use(Loading);
export default {
  name: "HomeProductTraceComponent",
  props: [
    "totalStageNum",
    "first",
    "last",
    "stageList",
    "supplierAuth1",
    "supplierAuth2",
    "supplierAuth3",
    "supplierName",
  ],
  data() {
    return {
      show: false,
      feedingRecordList: [],
      loadingAll: false,
      loading: false,
      progressIndex: 0,
      farm: {},
      showFarm: false,
    };
  },
  methods: {
    onClickFarm(item) {
      console.log(item);
      if (!item.farmManagerId) {
        return;
      }
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{farmManagerId}/farmManagerInfo".replace(
          "{farmManagerId}",
          item.farmManagerId
        );
      post(url, {}, false)
        .then((res) => {
          // this.vaccinumRecordList = res.data.breedingBatchStageVaccineList;

          this.farm = res.data;

          this.showFarm = true;

          setTimeout(() => {
            this.initFarmMap(); // 渲染地图
          }, 500);
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    initFarmMap() {
      let latitude = Number(this.farm.latitude);
      let longitude = Number(this.farm.longitude);

      console.log("initFarmMap = " + latitude + " ; " + longitude);

      if (this.farmMap) {
        this.farmMap.setCenter(new TMap.LatLng(latitude, longitude));
        this.farmMarkerLayer.updateGeometries([
          {
            styleId: "myStyle",
            id: "1",
            position: new TMap.LatLng(latitude, longitude),
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ]);

        return;
      }

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.farmMap = new TMap.Map("farmMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.farmMarkerLayer = new TMap.MultiMarker({
        map: this.farmMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    gotostep(i) {
      this.progressIndex = i;
    },
    changgeStep(index) {
      this.progressIndex = index;
    },

    showDialog(stageId) {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo".replace(
          "{stageId}",
          stageId
        );
      post(url, {}, false)
        .then((res) => {
          this.feedingRecordList = res.data.breedingBatchStageFeedingList;

          this.showFeedingRecord = true;
          this.show = true;
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    onClickFirst() {
      this.$parent.onClickShowBusiness(this.first.trustList);
    },
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
 
    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case "EVERYDAY":
            return "每日投喂";
          case "THREE_DAYS":
            return "三日一次";
          case "TWO_DAYS":
            return "两日一次";
          case "SINGLE_TIME":
            return "单次投喂";
          default:
            return "";
        }
      }
      return "";
    },
  },
  mounted() {
  },
};
</script>
  
<style lang="less" scoped>
.all {
  background-image: linear-gradient(to bottom, #c12820, #ff8c3e);
  width: 100vw;
  padding-bottom: 130px;
  margin-bottom: -100px;
  position: relative;
  .checkBackground{
    width: 100%;
    height: 248px;
    position: absolute;
    bottom: 87px;
  }
  .progressContainer {
    padding: 28px;
    .dayContainer {
      display: flex;
      justify-content: space-between;
      .dayleft {
        font-size: 16px;
        color: #ffff94;
      }
      .dayright {
        width: 108px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #ffff94;
      }
    }
    .progressLineContainer {
      height: 8px;
      border-radius: 16px;
      background-color: #972820;
      margin-top: 10px;
      position: relative;
      .progress1 {
        position: absolute;
        top: 0;
        display: flex;
        height: 100%;
        .progress1Line {
          border-radius: 16px;
          height: 100%;
          width: 15px;
          background-image: linear-gradient(to right, #ffe390, #fc702d);
          // #ffe390
        }
        .progress1Dot {
          width: 12px;
          height: 12px;
          margin-left: -5px;
          margin-top: -1.5px;
          border-radius: 10px;
          background-image: linear-gradient(-135deg, #ffe390, #fc702d);
        }
      }
      .progress2 {
        position: absolute;
        top: 0;
        display: flex;
        height: 100%;
        .progress1Line {
          border-radius: 16px;
          height: 100%;
          width: 236px;
          background-image: linear-gradient(to right, #ffe390, #fc702d);
          // #ffe390
        }
        .progress1Dot {
          width: 12px;
          height: 12px;
          margin-left: -5px;
          margin-top: -1.5px;
          border-radius: 10px;
          background-image: linear-gradient(-135deg, #ffe390, #fc702d);
        }
      }
      .progress3 {
        position: absolute;
        top: 0;
        display: flex;
        height: 100%;
        .progress1Line {
          border-radius: 16px;
          height: 100%;
          width: 317px;
          background-image: linear-gradient(to right, #ffe390, #fc702d);
          // #ffe390
        }
        .progress1Dot {
          width: 12px;
          height: 12px;
          margin-left: -5px;
          margin-top: -1.5px;
          border-radius: 10px;
          background-image: linear-gradient(-135deg, #ffe390, #fc702d);
        }
      }
    }
    .iconContainer {
      width: 100%;
      display: flex;
      .icon1 {
        margin-left: 14px;
        width: 73px;
        height: 49px;
        
        // background: url("../../assets/images/project3/progress1Icon.png")
        //   no-repeat center / cover;
          background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_1.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        color: #ffe1c5;
      }
      .icon1Select {
        
       margin-left: 14px;
        width: 73px;
        height: 49px;
        // background: url("../../assets/images/project3/progress1SelectIcon.png")
        //   no-repeat center / cover;
        background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_1s.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        color: #D82A1F;
        font-weight: bold;
      }
      .icon2 {
        margin-left: 53px;
        width: 98px;
        height: 49px;
        // background: url("../../assets/images/project3/progress2Icon.png")
        //   no-repeat center / cover;
        background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_3.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        padding-left: 9px;
        font-size: 14px;
        color: #ffe1c5;
      }
      .icon2Select {
        margin-left: 53px;
        width: 98px;
        height: 49px;
        background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_3s.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        padding-left: 9px;
        font-size: 14px;
        color: #D82A1F;
        font-weight: bold;
      }
      .icon3 {
        margin-left: 9px;
        width: 73px;
        height: 49px;
        background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_2.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        padding-left: 9px;
        font-size: 14px;
        color: #ffe1c5;
      }
      .icon3Select {
        margin-left: 9px;
        width: 73px;
        height: 49px;
        background: url("https://asset.fsytss.com/trace/wcj/icon_trace_f2_2s.png")
          no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        color: #D82A1F;
        font-weight: bold;
      }
    }
  }
  .detailContainer {
    width: 100vw;
    .imgcontainer {
      position: relative;
        z-index: 3;
      width: 100vw;
      height: 203px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .imgleft {
        width: 100px;
        height: 100px;
        border-radius: 203px;
        margin-left: -50px;
        overflow: hidden;
        margin-bottom: 24px;
        border: 8px solid rgb(254 208 206);
        opacity: 0.7;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .imgMid {
        width: 203px;
        height: 203px;
        border-radius: 203px;
        border: 8px solid rgb(254 208 206);
        position: relative;
        // overflow: hidden;
        display: flex;
        align-items: center;
        img {
          border-radius: 203px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .goto1 {
          position: absolute;
          top: 70px;
          left: -50px;
          font-size: 32px;
          color: #ffe1c5;
        }
        .goto2 {
          position: absolute;
          top: 70px;
          right: -50px;
          font-size: 32px;
          color: #ffe1c5;
          
        }
      }
      .imgright {
        width: 100px;
        height: 100px;
        border-radius: 203px;
        margin-right: -50px;
        overflow: hidden;
        margin-bottom: 24px;
        border: 8px solid rgb(254 208 206);
        opacity: 0.7;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .infocontainer {
      width: 298px;
      border: 1px solid transparent;
      margin: 0 auto;
      margin-top: -90px;
      padding: 0 29px;
      box-sizing: border-box;
      position: relative;
      .backgroundtRACE{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        img{
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
        }
      }
      .topcontainer {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 100px;
        margin-bottom: 8px;
        .stepTagContainer {
          position: relative;
        z-index: 2;
          width: 100px;
          height: 27px;
          display: flex;
          img {
            width: 27px;
            height: 27px;
            z-index: 1;
          }
          .tagBackground {
            
            width: 79px;
            height: 27px;
            background-color: #d82a1f;
            margin-left: -16px;
            border-radius: 5px;
            padding: 5px 4px 7px 19px;
            color: #fff;
            font-size: 13px;
            font-weight: 900;
          }
        }
        .situation {
          position: relative;
        z-index: 2;
          width: 75px;
          height: 22px;
          background-color: #f1733a;
          font-weight: 500;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          border-radius: 6px;
        }
      }
      .infoitem {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 2px 0;
        display: flex;
        justify-content: space-between;
        align-items: start;
        font-size: 15px;
        color: #333333;
        line-height: 28px;

        .key {
          display: flex;
          line-height: 16px;
        }
        .value {
          line-height: 16px;
          text-align: right;
          width: 60%;
        }
      }
      .infoLine {
        position: relative;
        z-index: 2;
        background-color: #f0d0c6;
        width: 100%;
        height: 1px;
        margin: 6px 0;
      }
      .date{
        position: relative;
        z-index: 2;
        width: 100%;
        height: 41px;
        margin: 0 auto;
        margin-top: 7px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 15px;
color: #333333;
line-height: 20px;
      }
    }
  }
 
}
.box {
  background: #fff;
  border-radius: 6px;
  padding: 8px;
  margin: 10px 0;

  & + .box {
    margin-top: 16px;
  }

  .title {
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }

    &::after {
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
</style>