<template>
  <div>
    <!-- 出品企业 -->
    <div class="fromenterprise">
      <div class="baseinfo">出品企业</div>
      <div class="name">{{ supplierName }}</div>
    
      <div class="businessTagContainer">

       
        <div class="businessTag" v-if="business.authenticate1">
          <img
            style="width: 19px; height: 19px; display: block"
            src="../../assets/images/project1/businessTag.png"
            alt=""
          />
          <div
            style="
              background-color: #f6e2c8;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              font-size: 9px;
              color: #ac402f;
              display: flex;
              align-items: center;
              padding: 0 6px;
              line-height: 9px;
            "
          >
            {{ business.authenticate1 }}
          </div>
        </div>
        <div style="margin: 0 8px;" class="businessTag" v-if="business.authenticate2">
          <img
            style="width: 19px; height: 19px; display: block"
            src="../../assets/images/project1/businessTag.png"
            alt=""
          />
          <div
            style="
              background-color: #f6e2c8;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              font-size: 9px;
              color: #ac402f;
              display: flex;
              align-items: center;
              line-height: 9px;
              padding: 0 6px;
            "
          >
            {{ business.authenticate2 }}
          </div>
        </div>
        <div class="businessTag" v-if="business.authenticate3">
          <img
            style="width: 19px; height: 19px; display: block"
            src="../../assets/images/project1/businessTag.png"
            alt=""
          />
          <div
            style="
              background-color: #f6e2c8;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              font-size: 9px;
              color: #ac402f;
              padding: 0 6px;
              display: flex;
              align-items: center;
              line-height: 9px;
            "
          >
            {{ business.authenticate3 }}
          </div>
        </div> 


      </div>
      <div class="intruduceInfo">
        <!-- {{ business.introduction }} -->
        <div class="pargath" v-for="(item,index) in data.introductionArr" :key="index">{{ item }}</div>
      </div>
    </div>
    <!-- 企业资质 -->
    <div
      class="fromenterprise2"
      style="padding-bottom: 5px"
      v-if="business.itemList.length != 0"
    >
      <div class="baseinfo">企业资质</div>
      <div
        v-for="(item, index) in business.itemList"
        :key="index"
        style="margin-bottom: 16px"
      >
        <div class="first" style="display: flex">
          <img
              v-show="item.length == 2"
              v-for="(item2, index2) in item"
              :key="index2"
              class="secondImg"
              :src="item2.image"
              alt=""
              @click="reportClick(item,index2)"
            />
            <img
              v-show="item.length == 1"
              v-for="(item3, index3) in item"
              class="firstImg"
              :src="item3.image"
              :key="item3.id"
              alt=""
              @click="reportClick(item,index3)"
            />
        </div>
        <div
          style="
            text-align: center;
            margin-top: -8px;
            z-index: 1;
            position: relative;
          "
        >
          <img
            v-if="index == 0"
            style="width: calc(100% - 32px); height: 43px"
            src="https://asset.fsytss.com/trace/wcj/icon_business_f1_1.png"
            alt=""
          />
          <div
            style="
              margin-top: -27px;
              font-weight: bold;
              font-size: 15px;
              color: #b54230;
              line-height: 20px;
            "
            v-if="index == 0"
          >
            文昌鸡地理标志认证资质
          </div>
          <img
            v-else
            style="width: calc(100% - 32px); height: 23px"
            src="https://asset.fsytss.com/trace/wcj/icon_business_f1_2.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 产地定位 -->
    <div class="fromenterprise2" v-if="business.latitude">
      <div class="baseinfo">产地定位</div>
      <div class="placeAddress">
        <div class="address" id="businessMap"></div>
        <div v-if="business.address" class="addressInfo">地址：{{ business.address }}</div>
        <div v-if="mobile" class="addressInfo" >联系方式：{{ mobile }}</div>
      </div>
    </div>
    <!-- 企业区块链 -->
    <div class="fromenterprise2" style="padding-top: 0">
      <div class="baseinfo">企业区块链</div>

      <div class="area" id="areaCanvasImg">
        <div class="clickDownLaod" @click="imagePreview(url)">点击放大 ></div>
        <div class="areaContainer" ref="canvasImg">
            <img   src="../../assets/images/project1/areaBlueBusiness.png" alt=""  />
            <div   class="img" >  
              <div>海南省文昌市畜牧兽医服务中心</div>
              <div class="only">{{ supplierContractId }}</div>
              <div>{{ businessFattenPushPopTime}}</div>
            </div>
        </div>
      </div>

      <!-- <div class="area" style="background: #fff; padding: 0 9px">
        <div
          style="
            display: flex;
            justify-content: flex-end;

            margin-right: 7px;
          "
        >
          <div
            style="color: #b44130; margin-top: 8px; font-size: 13px"
            @click="imagePreview(url)"
          >
            点击放大 >
          </div>
        </div>
        <div class="areaimg" ref="canvasImg"  >
          <div class="areaimgText" >
            <div>海南省文昌市畜牧兽医服务中心</div>
            <div class="only">{{ supplierContractId }}</div>
            <div>{{ businessFattenPushPopTime }}</div>

          </div>
        </div>
      </div> -->


    </div>
    <!-- 底部技术支持 -->
    <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
      <!-- 加载loading -->
      <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vPlayBack from "v-playback";
import moment from "moment";
import { Image as VanImage, ImagePreview ,Loading} from "vant";
import html2canvas from "html2canvas";
Vue.use(VanImage).use(ImagePreview).use(Loading);
Vue.use(vPlayBack);

export default {
  name: "HomeBusinessInfoComponent",
  props: ["business", "url", "supplierName",'supplierContractId','businessFattenPushPopTime','mobile'],
  data() {
    return {
      loadingAll:false,
      data: {
        title: "出品企业",
        businessMap: null,
        map: null,
        businessMarkerLayer: null,
        introductionArr:[]
      },
    };
  },
  mounted() {
   this.data.introductionArr = this.business.introduction.split('\n').filter(paragraph => paragraph.trim() !== '');
    this.initMap();
    this.formattList();
  },
  methods: {
    reportClick(arr, index) {
      arr = arr.map((item) => {
        return item.image;
      });
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    async createImage() {
      try {
        // const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        // const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        // this.imgUrl = image;
        // ImagePreview([image]);

        
        var canvas2 = document.createElement("canvas");
            let _canvas = this.$refs.canvasImg;
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);
            //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
            canvas2.width = w * 2;
            canvas2.height = h * 2;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";
            const context = canvas2.getContext("2d");
            context.scale(1,1);
            context.fillStyle = '#FFFFFF'
            context.fillRect(0, 0, canvas2.width, canvas2.height);


            const config = {
            backgroundColor: null,
            canvas: canvas2,
            useCORS: true,//配置允许跨域
            scale: 2,
            dpi: 800 // 处理模糊问题
            };
            console.log("获取指定的宽高", w, h, canvas2);

            const canvas = await html2canvas(this.$refs.canvasImg,config).then(canvas => {
            
            
            try {
                // 转成图片，生成图片地址
                this.imgUrl = canvas.toDataURL("image/png");  
                    ImagePreview([this.imgUrl]);
                } catch (e) {
                alert("图片跨域,保存失败");
                }


            });
        this.loadingAll=false
      } catch (e) {
        this.loadingAll=false
        throw new Error(e);
       
      }
    },
    imagePreview(url) {
      this.loadingAll=true
      this.createImage();
      
    },
    initMap() {
      if (!this.business.latitude) return;
      let latitude = Number(this.business.latitude);
      let longitude = Number(this.business.longitude);

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "111", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    formattList() {
      if (!this.business.itemList || this.business.itemList == "") return;
      let list = this.business.itemList;
      const groupedData = list.reduce((acc, item) => {
        const { lineNumber } = item;
        if (!acc[lineNumber]) {
          acc[lineNumber] = [];
        }
        acc[lineNumber].push(item);
        return acc;
      }, {});
      const result = Object.values(groupedData);
      this.business.itemList = result;
    },
  },
};
</script>

<style lang="less" scoped>
.fromenterprise {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 16px;
}
.fromenterprise2 {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 29px;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(-135deg, rgb(192, 73, 51), rgb(226, 187, 125));
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
// 企业荣誉
.first {
  width: calc(100% - 32px - 32px);
  position: relative;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  .firstImg {
    margin: 0 auto;
    max-width: 100%;
    height: 45vw;
    display: block;
    object-fit: contain;
  }
  .secondImg {
    max-width: calc(50% - 4px);
    height: 100%;
    max-height: 35vw;
    display: block;
    object-fit: contain;
    // max-width: calc(50% - 4px);
    // height: 35vw;
    // display: block;
    // object-fit: contain;

    
  }
}

.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  color: #231815;
  text-align: center;
}
.intruduceInfo {
  padding: 12px 21px;
  padding-bottom: 0;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  text-indent: 16px;
  .pargath:not(:last-child) {
    margin-bottom: 12px;
}
}
.placeAddress {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 6px;
  padding-bottom: 14px;
}
.address {
  width: 287px;
  height: 193px;
  margin: 14px auto;
  //   border: 1px solid #000;
}
.addressInfo {
  font-size: 13px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  padding: 0 10px;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.businessTagContainer {
  // text-align: center;

  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .businessTag {
    display: flex;
    justify-content: center;
    margin-right: 6px;
  }
}
.area {
  border-radius: 6px;
  padding-bottom: 8px!important;
  background: #fff;
  padding-top: 8px;
  .clickRedText{
    color: #d82a1f;
    font-size: 13px;
    // padding: 0 12px;
    text-align: right;
    margin-bottom: 7px;
    
  }
  .clickDownLaod {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    color: #b44130; font-size: 13px
  }
  .areaContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    position: relative;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
      margin: 6px auto 0 auto;
    
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 69px; //18.2
      padding-left: 90px;
      padding-right: 25px;
      font-size: 12px;
      word-break: break-all;
      color: #231815;
      text-align: right;
      line-height: 16px;
      .only {
        margin: 8px 0 4px 0;
      }
    }
  }
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // width: 100%;
  // height: 210px;
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_blue_business.png") no-repeat
  //   center / cover;
    background: url("../../assets/images/project1/areaBlueBusiness.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}
.areaimgText{
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;
  text-align: right;
  div{
    font-size: 12px;
    word-break: break-all; 
    color: #231815;
line-height: 16px;
  }
  .only{
    margin: 8px 0 4px 0;
  }
}
</style>