<template>
  <div class="originContainer">
    <div class="intruduce">
    <div class="img">
      <img v-if="sourceImage" style="height: 100%;width: 100%;" :src="sourceImage" alt="">
      <img v-if="!sourceImage" style="height: 100%;width: 100%;" src="https://asset.fsytss.com/trace/wcj/bg_default_img_1.png" alt="">
    </div>

      <div class="intruduceInfo">
        <div class="tagAround">原 产 地 简 介</div>
      <div style="text-indent: 2em;"> {{ sourceIntroduction }}</div>
      </div>
    </div>
    <!-- 视频 -->
   <div class="mp3Container">


    <div class="baseinfo" v-if="sourcePlayAddressUrl">
      <div class="placeAddress">
        <div class="mp3" @click="onClickPlay">
          <v-playback :url="data.url" v-if="data.url"></v-playback>
          <img
            alt=""
            src="https://asset.fsytss.com/trace/wcj/index_default.png"
            style="height: 56vw; width: 100%; border-radius: 6px;display: block;"
            v-if="!data.play&&!sourceVideoCoverUrl"
          />
          <img
            alt=""
            :src="sourceVideoCoverUrl"
            style="height: 56vw; width: 100%; border-radius: 6px;display: block;"
            v-if="!data.play&&sourceVideoCoverUrl"
          />
          <img
            alt=""
            src="../../assets/images/project1/play.png"
            style="position: absolute;top: 25vw;left:calc(50% - 7vw);width: 15vw;height: 15vw;display: block"
            v-if="!data.play"
          />
        </div>
      </div>
    </div>
    <!-- 支持 -->
      <!-- 底部技术支持 -->
      <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
   </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import vPlayBack from "v-playback";
import { Image as VanImage } from "vant";
Vue.use(VanImage);
Vue.use(vPlayBack);

export default {
  name: "HomeProductTraceComponent",
  props: [
    "sourceImage",
    "sourceIntroduction",
    "sourceAddress",
    "sourcePlayAddressUrl",
    "latitude",
    "longitude",
    "sourceVideoCoverUrl"
  ],
  data() {
    return {
      data: {
        title: "原产地简介",

        map: null,

        url: "",
        autoPlay: false,
        play: false,
        // url: 'https://vide.fsytss.com/ImportVideo_799692.911942%20%281%29.mp4'
      },
    };
  },
  mounted() {
  },
  methods: {
    init() {},
    initMap() {
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);

      // latitude = 37.86236
      // longitude = 113.58762

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      // // 手绘地图渲染,主要个性化手绘地图 参考文章2.个性化手绘地图
      // this.mapLayer();
      // // 图标和气泡窗的显示 参考文章3.地图标点信息+图标
      // this.multiMarker();
      // this.infoWindow();

      //创建并初始化MultiMarker
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },

    onClickPlay() {
      this.data.play = true;
      this.data.url = this.sourcePlayAddressUrl;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.intruduce {
  position: relative;
  background-color: #fff;
  padding: 0 16px;
  justify-content: center;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 19px;
  background-image: linear-gradient(to bottom, #fe975b, #d63815);
}
.img{
  width: 343px;
  height: 205px;
}
.intruduceInfo {

  padding: 12px 33px;
  padding-top: 0;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  text-indent: 16px;
  // background-image: linear-gradient(to bottom, #fff, #ffbfbf);
  background-image: linear-gradient(to bottom,
        #fff 0%,       /* 开始颜色 */
        #fff 40%,      /* 在中间位置变为另一个颜色 */
        #ffbfbf 100%);    /* 结束颜色 */
        
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}
.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  padding: 11px 0 20px 0;
  position: relative;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  padding: 11px;
  box-sizing: border-box;
  border-radius: 6px;
}
.mp3 {
  flex: 1;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.originContainer{
  // background-color: red;
 
  margin-top: -40px;
  padding-top: 40px;
}
.mp3Container{
  background-color: #ffe1c5;
  padding-top: 18px;
}
.tagAround {
  width: 240px;
  height: 6px;
  margin: 21px auto;
  margin-bottom: 18px;
  // margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  color: #d82a1f;
  background: url("../../assets/images/project2/tagAround.png") no-repeat center /
    cover;
  // background-color: red;
}
.bottom {
  padding-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
</style>