<template>
  <div
    id="app"
    style="
      height: auto;
      min-height: 100vh;
      padding-bottom: 8px;
      background-color: #fff;
      overflow: hidden;
    "
  >
  <div>
       
        <div class="tagContent" v-if="tabIndex==0">
          <HomeProductComponent
          v-if="data"
          :business="business"
            :productDetailsFigureImageUrl="data.productDetailsFigureImageUrl"
            :latitude="data.latitude"
            :longitude="data.longitude"
            :url="data.manufactureOrderContractUrl"
            :data="productData"
            :mobile="business.mobile"
            :productFattenPushPopTime="data.productFattenPushPopTime"
          ></HomeProductComponent>
        </div>
        <div class="tagContent" v-if="tabIndex==1">
          <HomeProductTraceComponent
          :stage-list="data.stageList"
            :supplierAuth1="data.supplierAuth1 && data.supplierAuth1"
            :supplierAuth2="data.supplierAuth2 && data.supplierAuth2"
            :supplierAuth3="data.supplierAuth3 && data.supplierAuth3"
            :supplierName="data.supplierName && data.supplierName"
          ></HomeProductTraceComponent>
        </div>
        <div class="tagContent" v-if="tabIndex==2">
          <HomeBusinessInfoComponent
          ref="homeBusinessInfoComponent"
            :url="data.manufactureOrderContractUrl"
            :latitude="data.latitude"
            :longitude="data.longitude"
            :business="business"
            :supplierName="data.supplierName && data.supplierName"
            :supplierContractId="data.supplierContractId"
            :businessFattenPushPopTime="data.businessFattenPushPopTime"
            :mobile="business.mobile"
          ></HomeBusinessInfoComponent>
        </div>
        <div class="tagContent" v-if="tabIndex==3">
          <HomeOriginInfoComponent
          :stage-list="data.stageList"
          :source-play-address-url="data.sourcePlayAddressUrl"
            :source-image="this.data.sourceImage"
            :source-introduction="data.sourceIntroduction"
            :source-address="data.sourceAddress"
            :sourceVideoCoverUrl="data.sourceVideoCoverUrl"
            :latitude="data.latitude"
            :longitude="data.longitude"
          ></HomeOriginInfoComponent>
        </div>
       
        <div class="tagContainer">
          <div class="tag" @click="onClickChangeTab(0)">
           <div v-show="tabIndex == 0 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_1_s.png " alt=""> </div>
           <div v-show="tabIndex != 0 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_1.png " alt=""> </div>
           <div :class="tabIndex == 0?'pegeTextSelect':'pegeText'"> 产品信息</div>
          </div>
          <div  class="tag" @click="onClickChangeTab(1)">
           <div v-show="tabIndex == 1 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_2_s.png" alt=""> </div>
           <div v-show="tabIndex != 1 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_2.png" alt=""> </div>
             <div :class="tabIndex == 1?'pegeTextSelect':'pegeText'"> 全流程溯源</div>
          </div>
          <div  class="tag" @click="onClickChangeTab(2)">
           <div v-show="tabIndex == 2 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_3_s.png" alt=""> </div>
           <div v-show="tabIndex != 2 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_3.png" alt=""> </div>
             <div :class="tabIndex == 2?'pegeTextSelect':'pegeText'"> 出品企业</div>
          </div>
          <div  class="tag" @click="onClickChangeTab(3)">
           <div v-show="tabIndex == 3 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_4_s.png" alt=""> </div>
           <div v-show="tabIndex != 3 " class="pageIcon"> <img src="https://asset.fsytss.com/trace/wcj/icon_tab_f3_4.png" alt=""> </div>
             <div :class="tabIndex == 3?'pegeTextSelect':'pegeText'"> 文昌鸡起源</div>
          </div>
        </div>
         <div class="tagContainer2"></div>
        <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support2.png" alt=""></div>
        <!-- ® -->
      </div>
    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HomeProductTraceComponent from "./HomeProductTraceComponent";
import HomeOriginInfoComponent from "./HomeOriginInfoComponent";
// import {Chart} from '@antv/g2';
import { Swipe, SwipeItem, Tab, Tabs, Toast, Loading } from "vant";
import { post } from "../../utils/http";
import { getDiffDay} from "../../utils/util";
import moment from "moment";
import HomeProductComponent from "./HomeProductComponent";
import HomeBusinessInfoComponent from "./HomeBusinessInfoComponent";
Vue.use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(Toast).use(Loading);
export default {
  name: "home",
  components: {
    HomeBusinessInfoComponent,
    HomeProductComponent,
    HomeProductTraceComponent,
    HomeOriginInfoComponent,
  },
  data() {
    return {
      // 新数据
      active: 0,
      tabIndex: 0,
      // 旧数据
      firstLoad: true,
      traceCode: "",
      data: null,
      totalStepNumber: 0,
      showOrderContractImage: false,

      sourceImage: "",
      sourceIntroduction: "",

      loadingAll: false,
      loading: false,
      tabIndex: 0,

      show: false,
      chart: null, //图表对象
      showData: [
        //图表中将要显示的数据
      ],

      showFeedingRecord: false,
      feedingRecordList: [],

      showVaccinumRecord: false,
      vaccinumRecordList: [],

      showBusiness: false,
      business: {
        title: "文昌鸡原产地简介",
        url: "",
        content:
          "安静的歌i加上见到过i哦啊手机导购i啊阿杰地阿佛第四届高is的架构as低结构i阿杰地宫颈癌搜地更煎熬is的警告是第几个",
        list: [{}, {}],
      },
      businessMap: null,
      businessMarkerLayer: null,

      showFarm: false,
      farm: {},
      farmMap: null,
      farmMarkerLayer: null,

      productData: {},
    };
  },

  mounted() {

    

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }

    this.traceCode = this.$route.query.traceCode;
    if (this.traceCode && this.traceCode !== "") {
      this.requestData();
    }
    // 初始化腾讯地图
    this.geolocation = new qq.maps.Geolocation('6JFBZ-U4PLW-QGDRD-3H6YZ-2L7L7-QDFMA', 'H5-文昌鸡');
    // 获取到溯源码信息后 ,开始定位
    this.getMyLocation();
   
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },

  methods: {
    

     // 获取当前位置
     getMyLocation() {
      
      this.geolocation.getLocation(this.showPosition, this.errorPosition); //开启定位
    },
    // 定位成功
    showPosition(position) {

      if(!position){
        return
      }
      // var str = JSON.stringify(position); 
      // alert(str)
      
      //获取当前地理位置后，请求后台
      const url =
        "/api/public/v2/manufacture/tracing/{traceCode}/matchSalesRegion".replace(
          "{traceCode}",
          this.traceCode
      );
      let params = {"position":position.province+position.city};
      post(url, params, false)
        .then((res) => {
         //isConsistent:1 是销售区域匹配，0是不匹配
          console.log()
          if(res.data){

            if(res.data.isConsistent ==0){

              alert("销售区域异常，请谨慎购买")
            }

          }
        })
        .catch((err) => {
          
        })
        .finally(() => {

        });
    },
    // 定位失败 继续尝试定位
    errorPosition(e) {
      console.log('定位失败，再次进行定位');
      // alert('定位失败，再次进行定位')
    },
   
    onClickChangeTab(index) {
      this.tabIndex = index;
    },
    goBack() {
      history.pushState(null, null, document.URL);
    },

    requestData() {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{uniqueCode}/findTrace".replace(
          "{uniqueCode}",
          this.traceCode
        );
      post(url, {}, false)
        .then((res) => {
          this.traceCode = res.data;
          this.requestDataAll();
        })
        .catch((err) => {
          this.requestDataAll();
        })
        .finally(() => {
          // this.loadingAll = false;
          this.firstLoad = false;
        });
    },
    requestDataAll() {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{tracingNo}/traceCodeScanInfo".replace(
          "{tracingNo}",
          this.traceCode
        );
      post(url, {}, false)
        .then((res) => {

          console.log(url)
          console.log(res.data)
          this.data = res.data;

          // 获取到溯源码信息后 ,开始定位
          //this.getMyLocation();
          

          if(this.data.productBannerImageUrl){
            this.data.productBannerImageUrl =
            this.data.productBannerImageUrl.split(";");

          }
          this.data.businessFattenPushPopTime = moment(
            this.data.supplierDigitalIdentityReportTime
          ).format("yyyy-MM-DD HH:mm:ss");
          this.data.productFattenPushPopTime = moment(
            this.data.fattenPushPopTime
          ).format("yyyy-MM-DD HH:mm:ss");
          if (
            !this.data.manufactureOrderContractId ||
            this.data.manufactureOrderContractId === ""
          ) {
            this.data.manufactureOrderContractId =
              "52f2275c2a758ea6b1e03ba45e9c5bb8020361fc8827b5f2bad7b5a64028deb086aa91ce8c3f7cc6ed15c29403ee9facf1a5ac1a93375ed86ce02ff73be0b232";
            this.data.manufactureOrderContractUrl =
              "https://asset.fsytss.com/srm-order/prod//2024/07/5ef55a41254248e48ebcc409ba73f230.jpg";
            this.data.manufactureOrderDigitalIdentityReportTime = 1720578927000;
          }
         
          this.onClickShowBusiness();

          this.sourceImage = this.data.sourceImage;
          this.sourceIntroduction = this.data.sourceIntroduction;

          let firstTrustList = [];
          if (this.data.supplierAuthChecked1 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth1,
            });
          }
          if (this.data.supplierAuthChecked2 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth2,
            });
          }
          if (this.data.supplierAuthChecked3 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth3,
            });
          }

          this.data.first = {
            name: this.data.supplierName,
            trustList: firstTrustList,
          };

          let nowDate = new Date(
            moment(new Date().getTime()).format("yyyy/MM/DD") + " 23:59:59"
          );
          let stageList = [];
          for (let breedingBatchStageIndex in this.data
            .breedingBatchStageList) {
            let breedingBatchStage =
              this.data.breedingBatchStageList[breedingBatchStageIndex];
            let startTime = breedingBatchStage.startTime
              ? new Date(
                  moment(breedingBatchStage.startTime).format("yyyy/MM/DD") +
                    " 00:00:00"
                )
              : null;
            let endTime = breedingBatchStage.endTime
              ? new Date(
                  moment(breedingBatchStage.endTime).format("yyyy/MM/DD") +
                    " 23:59:59"
                )
              : null;

            let cycleDay = 0;
            if (startTime && startTime <= nowDate.getTime()) {
              if (endTime && endTime > 0 && endTime <= nowDate.getTime()) {
                cycleDay = getDiffDay(startTime, endTime);
              } else {
                cycleDay = getDiffDay(startTime, nowDate);
              }
            }

            stageList.push({
              id: breedingBatchStage.id,
              stage: breedingBatchStage.stage,
              breedingFarmer: breedingBatchStage.breedingFarmer,
              traceAreaName: breedingBatchStage.traceAreaName,
              thumbnailImageUrl: breedingBatchStage.thumbnailImageUrl,
              cycleDay: cycleDay,
              step: breedingBatchStage.step,
              farmManagerId: breedingBatchStage.farmManagerId,
              farmManagerName: breedingBatchStage.farmManagerName,
              farmManagerCircleHouse: breedingBatchStage.farmManagerCircleHouse,
              vaccineName: breedingBatchStage.vaccineName,
              feedingName: breedingBatchStage.feedingName,
              pushTime: breedingBatchStage.pushTime,
              popTime: breedingBatchStage.popTime,
              formatpopTime: moment(breedingBatchStage.popTime).format(
                "yyyy-MM-DD"
              ),
              formatpushTime: moment(breedingBatchStage.pushTime).format(
                "yyyy-MM-DD"
              ),
              cycle:
                moment(breedingBatchStage.startTime).format("yyyy-MM-DD") +
                "至" +
                moment(breedingBatchStage.endTime).format("yyyy-MM-DD"),
            });
          }
          let cycleDay = 0;
          stageList.forEach((stage) => {
            cycleDay += stage.cycleDay;
          });

          if (this.data.first) {
            this.totalStepNumber++;
          }
          if (stageList.length > 0) {
            this.totalStepNumber += stageList.length;
          }
          this.data.stageList = stageList;

          this.productData = {
            manufactureQualityInspectFile:
              this.data.manufactureQualityInspectFile,
            manufactureAnimalQuarantineFile:
              this.data.manufactureAnimalQuarantineFile,
            sourceAddress: this.data.sourceAddress,
            supplierAddress: this.data.supplierAddress,
            fattenPushPopTime: this.data.fattenPushPopTime,
            formatFattenPushPopTime: moment(this.data.fattenPushPopTime).format(
              "yyyy年MM月DD日"
            ),
            formatFattenPushPopTimeTime: moment(
              this.data.fattenPushPopTime
            ).format("yyyy-MM-DD HH:mm:ss"),
            productThumbnailImageUrl: this.data.productThumbnailImageUrl,
            productName: this.data.productName,
            scanNumber: this.data.scanNumber,
            breedingBatchCode: this.data.breedingBatchCode,
            cycleDay: cycleDay,
            steps: this.data.steps,
            supplierName: this.data.supplierName,
            productSpec: this.data.productSpec,
            tracingNo: this.data.tracingNo,

            manufactureOrderContractId: this.data.manufactureOrderContractId,

            manufactureOrderDigitalIdentityReportTime: moment(
              this.data.manufactureOrderDigitalIdentityReportTime
            ).format("yyyy-MM-DD HH:mm:ss"),
            manufactureOrderContractUrl: this.data.manufactureOrderContractUrl,
            exceedPopFarmDays:this.data.exceedPopFarmDays,
            exceedScanNumber:this.data.exceedScanNumber
          };
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.firstLoad = false;
        });
    },

    onClickShowBusiness() {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{businessId}/businesAuthInfo".replace(
          "{businessId}",
          this.data.supplierId
        );
      post(url, {}, false)
        .then((res) => {
          // this.showBusiness = true;
          this.business = res.data;
          this.business.supplierDigitalIdentityReportTime = moment(
            this.business.supplierDigitalIdentityReportTime
          ).format("YYYY-MM-DD HH:mm:ss");

          if (
            this.business.corporateHonorsImg &&
            this.business.corporateHonorsImg !== ""
          ) {
            this.business.list = this.business.corporateHonorsImg.split(",");
            if (
              this.business.list.length > 0 &&
              this.business.list[this.business.list.length - 1] === ""
            ) {
              this.business.list.splice(this.business.list.length - 1, 1);
            }
          }

          // setTimeout(() => {
          //   this.initBusinessMap();// 渲染地图
          // }, 500);
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD HH:mm:ss");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getTime(startTime) {
      return moment(startTime).format("yyyy-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style lang="less" scoped>
 p{
      margin: 0px auto;
 }
.tagContainer2{
  height: 65px;
}
.tagContainer {
  width: 319px;
  height: 65px;
  // margin: 6px auto;
  bottom: 40px;
  left: calc(50vw - 160px);
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: linear-gradient(to bottom, #fbfbfb, #fff5cd);
  border-radius: 11px;
  z-index: 2000;
  box-shadow: 0 0 5px 0.1px rgba(0, 0, 0, 0.3);
  // margin: 0 auto;
.tag{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pageIcon{
    width: 30px;
    height: 30px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .pegeText{
    font-weight: 400;
font-size: 14px;
color: #666666;
line-height: 21px;
  }
  .pegeTextSelect{
    font-weight: 400;
font-size: 14px;
color: #dc5a37;
line-height: 21px;
  }
}
}
.bottom{
  font-size: 12px;
color: #FFFFFF;
line-height: 20px;
opacity: 0.8;
margin-top: 8px;
text-align: center;
position: relative;
z-index: 999;
}


</style>