<template>
  <div>
    <div class="intruduce" v-if="sourceIntroduction">
      <div class="infotag">原产地介绍</div>
      <div class="intruduceInfo">
       {{ sourceIntroduction }}
      </div>
    </div>
    <!-- 视频 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="sourcePlayAddressUrl">
     
      <div class="placeAddress">
        <div class="infotag">企业宣传片</div>
        <div class="mp3"  @click="onClickPlay">
          <!-- <video crossorigin="anonymous" preload="auto" src="blob:https://www.bilibili.com/a200fae8-3d27-472e-b062-3aab3b8691ba"></video> -->
          <v-playback :url="data.url" v-if="data.play"></v-playback>
          <img
            alt=""
            src="https://asset.fsytss.com/trace/wcj/index_default.png"
            style="height: 56vw; width: 100%; border-radius: 6px;display: block;"
            v-if="!data.play&&!sourceVideoCoverUrl"
          />
          <img
            alt=""
            :src="sourceVideoCoverUrl"
            style="height: 56vw; width: 100%; border-radius: 6px;display: block;"
            v-if="!data.play&&sourceVideoCoverUrl"
          />
          <img
            alt=""
            src="../../assets/images/project1/play.png"
            style="position: absolute;top: 20vw;left:calc(50% - 7vw);width: 15vw;height: 15vw;"
            v-if="!data.play"
          />
        </div>
      </div>
    </div>
    <!-- 支持 -->
      <!-- 底部技术支持 -->
      <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import vPlayBack from "v-playback";
import { Image as VanImage } from "vant";
Vue.use(VanImage);
Vue.use(vPlayBack);

export default {
  name: "HomeProductTraceComponent",
  props: [
    "sourceImage",
    "sourceIntroduction",
    "sourceAddress",
    "sourcePlayAddressUrl",
    "sourceVideoCoverUrl"
  ],
  data() {
    return {
      data: {
        title: "原产地简介",

        map: null,

        url: "",
        autoPlay: false,
        play: false,
        sourceIntroductionArr:[]
        // url: 'https://vide.fsytss.com/ImportVideo_799692.911942%20%281%29.mp4'
      },
    };
  },
  mounted() {
  },
  methods: {
    onClickPlay() {
      this.data.play = true;
      this.data.url = this.sourcePlayAddressUrl;
    },
    }


  
};
</script>
  
  <style lang="less" scoped>
.intruduce {
  position: relative;
  width: 319px;
  background-color: #fff;
  margin: 0 auto;
  justify-content: center;
  margin-top: 27px;
  border-radius: 6px;
}
.infotag {
  position: absolute;
  left: 0;
  top: 0px;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(-135deg, rgb(192, 73, 51), rgb(226, 187, 125));
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -11px;
}
.intruduceInfo {
  padding: 12px 21px;
  padding-top: 29px;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  text-indent: 16px;
}
.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  padding: 11px 0 20px 0;
  position: relative;
  
}
.placeAddress {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  padding: 11px;
  padding-top: 24px;
  box-sizing: border-box;
  border-radius: 6px;
}
.mp3 {
  position: relative;
  flex: 1;
}
.img{
  border-radius: 6px;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;

}
</style>