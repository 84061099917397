<template>
  <div style="overflow: hidden">
    <!-- 出品企业 -->

    <div class="fromenterpriseContainer">
      <div class="img">
          <img
            v-if="!business.imageUrl"
            style="height: 100%; width: 100%;display: block;"
            src="../../assets/images/project2/productImg.png"
            alt=""
          />
          <img
            v-if="business.imageUrl"
            style="height: 100%; width: 100%;display: block;"
            :src="business.imageUrl"
            alt=""
          />
        </div>
      <div class="fromenterprise">
       
        <div class="tagAround" style="margin-top: 0;">企 业 简 介</div>
        <div class="name">{{ supplierName }}</div>
        <div class="businessTagContainer">
          <div class="businessTag" v-if="business.authenticate1">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate1 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate2">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate2 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate3">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate3 }}
            </div>
          </div>
        </div>
        <div class="intruduceInfo">
          <!-- {{ business.introduction }} -->
          <div style="text-indent: 2em" class="pargath" v-for="(item, index) in data.introductionArr" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 企业资质 -->
    <div class="fromenterprise2Container">
      <div
        class="fromenterprise2"
        style="padding-bottom: 5px"
        v-if="itemList.length != 0"
      >
        <div class="tagAround" >
          企 业 资 质
        </div>
        <div
          v-for="(item, index) in itemList"
          :key="index"
          style="margin-bottom: 16px"
        >
          <div class="first" style="display: flex">
            <img
              v-show="item.length == 2"
              v-for="(item2, index2) in item"
              :key="index2"
              class="secondImg"
              :src="item2.image"
              alt=""
              @click="reportClick(item,index2)"
            />
            <img
              v-show="item.length == 1"
              v-for="(item3, index3) in item"
              class="firstImg"
              :src="item3.image"
              :key="item3.id"
              alt=""
              @click="reportClick(item,index3)"
            />
          </div>
          <div
            style="
              text-align: center;
              margin-top: -8px;
              z-index: 1;
              position: relative;
            "
          >
            <img
              v-if="index == 0"
              style="width: calc(100% - 32px); height: 43px"
              src="https://asset.fsytss.com/trace/wcj/icon_business_f23_1.png"
              alt=""
            />
            <div
              style="
                margin-top: -27px;
                font-weight: bold;
                font-size: 15px;
                color: #f1733a;
                line-height: 20px;
              "
              v-if="index == 0"
            >
              文昌鸡地理标志认证资质
            </div>
            <img
              v-else
              style="width: calc(100% - 32px); height: 23px"
              src="https://asset.fsytss.com/trace/wcj/icon_business_f23_2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 产地定位 -->
    <div v-if="business.latitude">
      <div class="placeAddress">
        <div class="addressTag">
          <div class="titleLeftLine"></div>
          产地定位
        </div>
        <div class="address" id="businessMap"></div>
        <div  class="addressInfo">
          <p>地址：{{ business.address }}</p>
          <p v-if="mobile">联系方式：{{ mobile }}</p>
        </div>
        
      </div>
    </div>

    <!-- 区块链信息 -->

    <div
      style="
       
        background-color: rgb(255, 225, 197);
        overflow: hidden;
      "
    >
      <div class="addressTag2">
        <div class="titleLeftLine2"></div>
        企业区块链
      </div>
        <areaCanvasImg :isred=true  :imgtype=1 :id="supplierContractId" :time="businessFattenPushPopTime"></areaCanvasImg>


      <!-- <div
        style="
          color: #d82a1f;
          font-size: 13px;
          padding: 0 12px;
          text-align: right;
          margin-bottom: 7px;
        "
        @click="imagePreview(url)"
      >
        点击放大 >
      </div>
      <div class="area">
       
        <div class="areaimg" ref="canvasImg">
          <div class="areaimgText">
            <div>
              海南省文昌市畜牧兽医服务中心
            </div>
            <div class="only">{{ supplierContractId }}</div>
            <div >
              {{ businessFattenPushPopTime }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- 底部技术支持 -->
      <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
    </div>

    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vPlayBack from "v-playback";
import moment from "moment";
import { Image as VanImage, ImagePreview, Loading } from "vant";
import areaCanvasImg from "../../components/canvasImgComponent.vue";
Vue.use(VanImage).use(ImagePreview).use(Loading);
Vue.use(vPlayBack);

export default {
  name: "HomeBusinessInfoComponent",
  components: {
    areaCanvasImg
  },
  props: [
    "business",
    "url",
    "supplierName",
    "supplierContractId",
    "businessFattenPushPopTime",
    "mobile"
  ],
  data() {
    return {
      loadingAll: false,
      itemList: [],
      data: {
        title: "出品企业",
        businessMap: null,
        map: null,
        businessMarkerLayer: null,
        introductionArr: [],
      },
    };
  },
  created() {
    this.data.introductionArr = this.business.introduction
      .split("\n")
      .filter((paragraph) => paragraph.trim() !== "");
    this.formattList();
  },
  mounted() {
    this.initMap();
  },
  methods: {
    reportClick(arr, index) {
      arr = arr.map((item) => {
        return item.image;
      });
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    // async createImage() {
    //   try {
    //     const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
    //     const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
    //     this.imgUrl = image;
    //     ImagePreview([image]);
    //     this.loadingAll = false;
    //   } catch (e) {
    //     this.loadingAll = false;
    //     throw new Error(e);
    //   }
    // },
    // imagePreview(url) {
    //   this.loadingAll = true;
    //   this.createImage();
    // },
    initMap() {
      if (!this.business.latitude) return;
      let latitude = Number(this.business.latitude);
      let longitude = Number(this.business.longitude);

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "111", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    formattList() {
      if (!this.business.itemList || this.business.itemList == "") {
        return;
      }
      let list = this.business.itemList;
      const groupedData = list.reduce((acc, item) => {
        const { lineNumber } = item;
        if (!acc[lineNumber]) {
          acc[lineNumber] = [];
        }
        acc[lineNumber].push(item);
        return acc;
      }, {});
      const result = Object.values(groupedData);
      this.itemList = result;
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
p{
      margin: 0px auto;
 }
.fromenterpriseContainer {
  padding-top: 42px;
  padding-bottom: 25px;
  padding-left: 16px;
  padding-right: 16px;
  background-image: linear-gradient(to bottom, #fc965a, #c22a21);
}
.fromenterprise {
  margin: 0 auto;
  background-image: linear-gradient(to bottom,
  #fff 0%,       /* 开始颜色 */
        #fff 50%,      /* 在中间位置变为另一个颜色 */
        #ffbfbf 100%);    /* 结束颜色 */
  padding-bottom: 16px;
  padding-top: 25px;
  border-bottom-left-radius:6px;
  border-bottom-right-radius:6px;
  overflow: hidden;
}
.fromenterprise2Container {
  padding-top: 23px;
  padding-bottom: 25px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #ffe1c5;
}
.fromenterprise2 {
  background-image: linear-gradient(to bottom,
        #fff 0%,       /* 开始颜色 */
        #fff 50%,      /* 在中间位置变为另一个颜色 */
        #ffbfbf 100%);    /* 结束颜色 */
  // border-radius: 6px;
  position: relative;
  padding-top: 16px;
  border-radius: 6px;
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
// 企业荣誉
.first {
  width: calc(100% - 32px - 32px);
  position: relative;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  .firstImg {
    margin: 0 auto;
    max-width: 100%;
    height: 45vw;
    display: block;
    object-fit: contain;
  }
  .secondImg {
    max-width: calc(50% - 4px);
    height: 100%;
    max-height: 35vw;
    display: block;
    object-fit: contain;



    // max-width: calc(50% - 4px);
    // height: 35vw;
    // display: block;
    // object-fit: contain;
  }
}

.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  color: #231815;
  // line-height: 20px;
  text-align: center;
}
.intruduceInfo {
  padding: 12px 20px;
  padding-bottom: 0;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  .pargath:not(:last-child) {
    margin-bottom: 12px;
}
}

.address {
  width: 287px;
  height: 193px;
  margin: 0 auto;
  margin-top: 16px;
  border-radius: 5px;
  overflow: hidden;
  //   border: 1px solid #000;
}

.bottom {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.businessTagContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 14px;
  .businessTag {
    display: flex;
    justify-content: center;
    // margin-right: 6px;
    img{
      width: 19px; height: 19px; display: block
    }

    .businessTagTitle{

      // background-color: #f6e2c8;
      border:1px solid #ac402f;
      border-left-width:0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 10px;
      color: #ac402f;
      padding: 0 2px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
/*
.area {
  border-radius: 6px;
  background: #fff;
  width: 319px;
  margin: 0 auto;
  padding: 2px 0;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_red_business.png") no-repeat center /
  //   cover;
  background: url("../../assets/images/project1/areaRedBusiness.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}
.areaimgText {
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;
  text-align: right;
  div {
    font-size: 12px;
    word-break: break-all;
    color: #231815;
    line-height: 16px;
  }
  .only{
    margin: 8px 0 4px 0;
  }
}
*/

.placeAddress {
  position: relative;
  background-image: linear-gradient(to bottom, rgb(253 151 91), rgb(194 42 33));
  overflow: hidden;
  padding-bottom: 30px;
  z-index: 1;
  overflow: hidden;
}
.address {
  width: 319px;
  height: 400px;
  // margin: 0 12px;
  border-radius: 5px;
  // margin-top: 29px;
  //   border: 1px solid #000;
  .mobile{
    text-align: center;color: #000;font-size: 13px;
  }
}
// .addressInfo {
//   font-size: 13px;
//   color: #ffffff;
//   line-height: 20px;
//   text-align: center;
//   margin-top: 10px;
//   margin-bottom: 16px;
//   padding: 0 16px;
// }
.addressInfo {
  width: 300px;
  text-align: center;
  font-size: 13px;
  color: #000;
  line-height: 16px;
  text-align: center;
  position: absolute;
  bottom: 80px;
  left: calc(50% - 150px);
  border-radius: 32px;
  // border: 1px solid #000;
  background-color: #fff;
  padding: 6px 18px;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0.1px rgba(0, 0, 0, 0.3);
  p{
    line-height: 18px;
  }
}
.addressTag {
  position: relative;
  width: 95px;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;
  margin: 30px 0 12px 28px;
}
.addressTag2 {
  position: relative;
  width: 95px;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  color: #d82a1f;
  display: flex;
  align-items: center;
  z-index: 2;
  margin: 30px 0 -12px 27px;
}
.titleLeftLine {
  width: 3px;
  height: 16px;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 6px;
}

.titleLeftLine2 {
  width: 3px;
  height: 16px;
  background-color: #d82a1f;
  margin-right: 8px;
  border-radius: 6px;
}
.tagAround {
  width: 240px;
  height: 6px;
  margin: 21px auto;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  color: #d82a1f;
  background: url("../../assets/images/project2/tagAround.png") no-repeat center /
    cover;
  // background-color: red;
  
}
</style>