<template>
  <div>
    <img
      v-if="data.productThumbnailImageUrl"
      class="image"
      :src="data.productThumbnailImageUrl"
      alt=""
    />
    <img
      v-if="!data.productThumbnailImageUrl"
      class="image"
      src="https://asset.fsytss.com/trace/wcj/index_default.png"
      alt=""
    />
    <div class="dateImgClass">
      <!-- <img src="../../assets/images/project1/indexdate.png" alt=""> -->
      <div>
        <div class="dateText">养殖</div>
        <div class="dateText" style="margin-top: 4px">天数:</div>
      </div>
      <div style="font-size: 33px; line-height: 33px">{{ data.cycleDay }}</div>
      <div style="font-size: 14px; line-height: 14px; margin-top: 12px">天</div>
    </div>
    <div class="progress">
      <img
        style="width: 80%"
        src="../../assets/images/project1/chickenProgress.png"
        alt=""
      />
      <img
        style="
          width: 63px;
          height: 67px;
          position: absolute;
          top: -30px;
          right: -10px;
          transform: scaleX(-1);
        "
        src="../../assets/images/project1/chickenGif.gif"
        alt=""
      />
    </div>

    <!-- 基本信息 -->
    <div class="baseInfo">
      <div class="infoContainer">
        <div class="info_bottom">
          <img
            src="../../assets/images/project1/tick.png"
            style="width: 41px; height: 41px; "
          />
          <div style="width: 250px; margin-left: 15px">
            <div
              style="
                font-size: 16px;
                color: #fff;
                font-weight: bold;
                line-height: 16px;
              "
            >
              {{ "真品验证通过！" }}
            </div>
            
            <div
              style="
                display: flex;
                color: #fff;
                margin-top: 8px;
                font-size: 12px;
                line-height: 12px;
                /* width: calc(100vw - 132px); */
                width: 50px;
              "
            >
              <div style="width: 59px">批次编号:</div>
              <div
                style="
                  flex: 1;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-break: break-all;
                "
              >
                {{ data.breedingBatchCode }}
                <!-- 222 -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="infotag">基本信息</div>

      <div class="infoItem">
        <div class="infodetail">
          <div class="key">产品名称</div>
          <div class="value">{{ data.productName }}</div>
        </div>
        <div class="line">
       
        </div>
      </div>
      <div class="infoItem" v-if="data.fattenPushPopTime">
        <div class="infodetail">
          <div class="key">收货日期</div>
          <div class="value">{{ data.formatFattenPushPopTime  }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">出品企业</div>
          <div class="value">{{ data.supplierName }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">商品规格</div>
          <div class="value">{{ data.productSpec }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">产地</div>
          <div class="value">{{ data.sourceAddress }}</div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <!-- 产地定位 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="latitude">
      <div class="addressTag">产地定位</div>
      <div class="placeAddress">
        <div class="address" id="container"></div>
        <div class="addressInfo">地址：{{ data.sourceAddress }}</div>
      </div>
    </div>
    <!-- 检测报告 -->
    <div
      class="baseinfo"
      style="margin-top: 26px"
      v-if="
        data.manufactureQualityInspectFile ||
        data.manufactureAnimalQuarantineFile
      "
    >
      <div class="addressTag">检测报告</div>
      <div class="placeAddress">
        <!-- 轮播 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-if="data.manufactureQualityInspectFile"
            ><img
              class="bannerImg"
              :src="data.manufactureQualityInspectFile"
              alt=""
              @click="reportClick('manufactureQualityInspectFile')"
          /></van-swipe-item>
          <van-swipe-item v-if="data.manufactureAnimalQuarantineFile"
            ><img
              class="bannerImg"
              :src="data.manufactureAnimalQuarantineFile"
              alt=""
              @click="reportClick('manufactureAnimalQuarantineFile')"
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 区块链信息 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="data.fattenPushPopTime">
      <div class="addressTag">区块链信息</div>
      <div class="area" style="background: #fff; padding: 0 9px">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            margin-right: 7px;
          "
        >
          <div
            style="color: #b44130; font-size: 13px"
            @click="imagePreview(url)"
          >
            点击放大 >
          </div>
        </div>
        <div class="areaimg" ref="canvasImg"  >
          <div class="areaimgText" >
            <div>海南省文昌市畜牧兽医服务中心</div>
            <div class="only">{{ data.manufactureOrderContractId }}</div>
            <div>{{ data.formatFattenPushPopTimeTime }}</div>

          </div>
          <!-- <img ref="canvasImg"  style="width: 100%; height: 100%" :src="url" alt="" /> -->
        </div>
      </div>
    </div>

    <!-- 底部技术支持 -->
    <div class="bottom">一天膳事提供技术服务</div>
     <!-- 加载loading -->
     <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ImagePreview ,Loading} from "vant";
import html2canvas from "html2canvas";
import moment from "moment";
Vue.use(ImagePreview).use(Loading);
export default {
  name: "HomeProductComponent",
  props: [
    "data",
    "latitude",
    "longitude",
    "url",
    "productDetailsFigureImageUrl",
  ],
  data() {
    return {
      imgUrl:'',
      loadingAll:false
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    reportClick(img){
      console.log(img);
      let arr=[]
      if(this.data.manufactureQualityInspectFile){
        arr.push(this.data.manufactureQualityInspectFile)
      }
      if(this.data.manufactureAnimalQuarantineFile){
        arr.push(this.data.manufactureAnimalQuarantineFile)
      }
      let index=0
      if(arr.length==1){
        index=0
      }else if(img=='manufactureAnimalQuarantineFile'){
        index=1
      }
      console.log(index);
      ImagePreview({
        images: arr,
        startPosition: index,
      });

    },
    async createImage() {
      try {
        const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        this.imgUrl = image;
        ImagePreview([image]);
        
      this.loadingAll=false
      } catch (e) {
        this.loadingAll=false
        throw new Error(e);
      }
    },
    imagePreview(url) {
      this.loadingAll=true
      this.createImage();
      
    },
    initMap() {
      if(!this.latitude) return
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);
      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.image {
  width: 319px;
  height: 221px;
  margin: 0 auto;
  margin-top: 11px;
  display: block;
  border-radius: 16px;
}
.dateImgClass {
  width: 152px;
  height: 44px;
  margin: 0 auto;
  margin-top: 11px;
  background: url("../../assets/images/project1/indexdate.png");
  background-size: cover;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding-left: 16px;
  padding-right: 35px;
  align-items: center;
  padding-bottom: 11px;
  padding-top: 9px;
  font-weight: 500;
  // line-height: 33px;
}
.dateText {
  font-size: 12px;
  line-height: 14px;
}
.progress {
  position: relative;
  // border: 1px solid #000;
  width: 230px;
  height: 34px;
  margin: 0 auto;
  margin-top: 24px;
}

.info_bottom {
  display: flex;
  background: url("../../assets/images/project1/passImg.png") no-repeat center /
    cover;
  border-radius: 8px;
  padding: 16px 19px;
}

.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  margin-top: 16px;
  padding: 0px 0 16px 0;
  position: relative;
  border-radius: 8px;
}

.infotag {
  position: relative;
  width: 95px;
  height: 23px;
  // background: url("../../assets/images/project1/infoTag.png") no-repeat center /
  //   cover;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: 11px;
}
.infoItem {
  width: 100%;
  margin-top: 15px;
  padding: 0 16px;
}
.line {
  height: 0.5px;
  background-color: rgb(139, 202, 157);
  margin-top: 10px;
}
.infodetail {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
// 产地定位
.addressTag {
  position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-left: 12px;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  height: 262px;
  margin-top: -11px;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  overflow: hidden;
}
.address {
  width: 287px;
  height: 193px;
  margin: 0 auto;
  margin-top: 29px;
  //   border: 1px solid #000;
}
.addressInfo {
  font-size: 13px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_blue_order.png") no-repeat
  //   center / cover;
  background: url("../../assets/images/project1/areaBlueOrder.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}
.areaimgText{
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;
  text-align: right;
  div{
    margin-bottom: 1vw;
    font-size: 12px;
    word-break: break-all; 
    color: #231815;
line-height: 16px;
  }
  .only{
    margin: 8px 0 4px 0;
  }
    
}
.area {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  margin-top: -11px;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  overflow: hidden;
  border-radius: 8px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  width: 287px !important;
  height: 220px !important;
  margin: 0 auto;
  line-height: 150px;
  text-align: center;
  margin-top: 24px;
  .bannerImg {
    max-width: 200px;
        height: 100%;
        max-height: 200px;
        object-fit: contain;
  }
}
.key {
  width: 120px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
.value {
  font-weight: 400;
  font-size: 13px;
  color: #231815;
  line-height: 20px;
}
</style>