<template>
  <div>
    <div class="backGround">
      <div class="img">
        <img
          v-if="!business.corporateHonorsImg"
          src="../../assets/images/project2/productImg.png"
          alt=""
        />
        <img v-if="business.imageUrl" :src="business.imageUrl" alt="" />
      </div>
      <!-- 出品企业 -->
      <div class="fromenterprise">
        <div class="topTitle">公 司 简 介</div>
        <div class="TitleLine"></div>
        <div class="name">{{ supplierName }}</div>

        <div class="businessTagContainer">
          <div class="businessTag" v-if="business.authenticate1">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate1 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate2">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate2 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate3">
            <img
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div class="businessTagTitle">
              {{ business.authenticate3 }}
            </div>
          </div>
        </div>


        <!-- <div class="businessTagContainer">
          <div class="businessTag" v-if="business.authenticate1">
            <img
              style="width: 19px; height: 19px; display: block"
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div
              style="
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                font-size: 9px;
                color: #ac402f;
                display: flex;
                align-items: center;
                padding: 0 6px;
                line-height: 9px;
                border: 1px solid #e18682;
              "
            >
              {{ business.authenticate1 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate2">
            <img
              style="width: 19px; height: 19px; display: block"
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div
              style="
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                font-size: 9px;
                color: #ac402f;
                display: flex;
                align-items: center;
                line-height: 9px;
                padding: 0 6px;
                
    border: 1px solid #e18682;
              "
            >
              {{ business.authenticate2 }}
            </div>
          </div>
          <div class="businessTag" v-if="business.authenticate3">
            <img
              style="width: 19px; height: 19px; display: block"
              src="../../assets/images/project3/businessTag.png"
              alt=""
            />
            <div
              style="
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                font-size: 9px;
                color: #ac402f;
                padding: 0 6px;
                display: flex;
                align-items: center;
                line-height: 9px;
                
    border: 1px solid #e18682;
              "
            >
              {{ business.authenticate3 }}
            </div>
          </div>
        </div> -->
        <div class="intruduceInfo">
          <div
            class="pargath"
            v-for="(item, index) in data.introductionArr"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="reg">
        <img
          style="width: 100%; height: 100%"
          src="../../assets/images/project3/reg.png"
          alt=""
        />
      </div>
    </div>
    <div class="box"></div>
    <div class="businessContainer">
      <!-- 企业资质 -->
      <div class="topTitle">企 业 资 质</div>
      <div class="TitleLine"></div>
      <div
        class="fromenterprise2"
        style="margin-top: 0"
        v-if="itemList.length != 0"
      >
        <div
          v-for="(item, index) in itemList"
          :key="index"
          style="margin-bottom: 16px"
        >
          <div class="first" style="display: flex">
            <img
              v-show="item.length == 2"
              v-for="(item2, index2) in item"
              :key="index2"
              class="secondImg"
              :src="item2.image"
              alt=""
              @click="reportClick(item, index2)"
            />
            <img
              v-show="item.length == 1"
              v-for="(item3, index3) in item"
              class="firstImg"
              :src="item3.image"
              :key="item3.id"
              alt=""
              @click="reportClick(item, index3)"
            />
          </div>
          <div
            style="
              text-align: center;
              margin-top: -8px;
              z-index: 1;
              position: relative;
            "
          >
            <img
              v-if="index == 0"
              style="width: calc(100% - 32px); height: 43px"
              src="https://asset.fsytss.com/trace/wcj/icon_business_f23_1.png"
              alt=""
            />
            <div
              style="
                margin-top: -27px;
                font-weight: bold;
                font-size: 15px;
                color: #f1733a;
                line-height: 20px;
              "
              v-if="index == 0"
            >
              文昌鸡地理标志认证资质
            </div>
            <img
              v-else
              style="width: calc(100% - 32px); height: 23px"
              src="https://asset.fsytss.com/trace/wcj/icon_business_f23_2.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 产地定位 -->
      <!-- <div class="fromenterprise3"> -->
      <div class="fromenterprise3" v-if="business.latitude">
        <div class="gpsTopText">产地定位</div>
        <div class="addressInfo">地址：{{ business.address }}</div>
        <div  v-if="mobile" class="mobile">联系方式：{{ mobile }}</div>
        <div class="placeAddress">
          <div class="address" id="businessMap"></div>
        </div>
      </div>
      <div class="reg2">
        <img
          style="width: 100%; height: 100%"
          src="../../assets/images/project3/reg2.png"
          alt=""
        />
      </div>
    </div>
    <div class="box2"></div>
    <!-- 企业区块链 -->
    <div class="areaContainer">
      <div class="areaToptext">企业区块链</div>


      <areaCanvasImg  :imgtype=1 :id="supplierContractId " :time=" businessFattenPushPopTime"></areaCanvasImg>
<!--      
      <div @click="imagePreview(url)" class="clickDownLoad">点击放大 ></div>
      <div class="fromenterprise2" style="padding-top: 0; margin-top: 0">
        <div class="area" style="background: #fff">

          

           <div class="areaimg" ref="canvasImg">
            <div class="areaimgText">
              <div>海南省文昌市畜牧兽医服务中心</div>
              <div class="only">{{ supplierContractId }}</div>
              <div>
                {{ businessFattenPushPopTime }}
              </div>
            </div>
          </div> 
        </div>
      </div> -->


    </div>
    <!-- 底部技术支持 -->
    <!-- <div class="bottom">一天膳事提供技术服务</div> -->
    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vPlayBack from "v-playback";
import moment from "moment";
import { Image as VanImage, ImagePreview, Loading } from "vant";
import areaCanvasImg from "../../components/canvasImgComponent.vue";
Vue.use(VanImage).use(ImagePreview).use(Loading);
Vue.use(vPlayBack);

export default {
  name: "HomeBusinessInfoComponent",
  props: [
    "business",
    "url",
    "supplierName",
    "supplierContractId",
    "businessFattenPushPopTime",
    "mobile",
  ],
  components: {
    areaCanvasImg
  },
  data() {
    return {
      loadingAll: false,
      itemList: [],

      data: {
        title: "出品企业",
        businessMap: null,
        map: null,
        businessMarkerLayer: null,
        introductionArr: [],
      },
    };
  },
  created() {
    this.data.introductionArr = this.business.introduction
      .split("\n")
      .filter((paragraph) => paragraph.trim() !== "");
    this.formattList();
  },
  mounted() {
    this.initMap();
  },
  methods: {
    reportClick(arr, index) {
      arr = arr.map((item) => {
        return item.image;
      });
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    
    initMap() {
      if (!this.business.latitude) return;
      let latitude = Number(this.business.latitude);
      let longitude = Number(this.business.longitude);

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "111", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    formattList() {
      if (!this.business.itemList || this.business.itemList == "") {
        return;
      }
      let list = this.business.itemList;
      const groupedData = list.reduce((acc, item) => {
        const { lineNumber } = item;
        if (!acc[lineNumber]) {
          acc[lineNumber] = [];
        }
        acc[lineNumber].push(item);
        return acc;
      }, {});
      const result = Object.values(groupedData);
      this.itemList = result;
    },
  },
};
</script>

<style lang="less" scoped>
.fromenterprise {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-image: linear-gradient(135deg, #fff, #fff1d1);
  border-radius: 6px;
  position: relative;
  padding-top: 13px;
  padding-bottom: 16px;
  position: relative;
}
.fromenterprise2 {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 29px;
}
.fromenterprise3 {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 18px;
  // background: url("../../assets/images/project3/gpsContainer.png") no-repeat
  //   center / cover;
    background-image: linear-gradient(to right, #ffdebb, #ffcdc5);
  //
  .mobile{
    margin-bottom: 9px;text-align: center;color: #000;font-size: 13px;
  }
}

.box {
  height: 30px;
  position: relative;
  margin: 0 -21px;
  margin-top: -28px;
  z-index: 4;
  text-align: center;
  line-height: 200px;
  overflow: hidden;
}
.box::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
  z-index: 3;
  border-radius: 100% 100% 0 0;
  background-color: #fff7d7;
}
.businessContainer {
  padding-top: 24px;
  margin-top: -32px;
  position: relative;
  border-radius: 6px;
  background-image: linear-gradient(to bottom,
  #fff6d0 0%,       /* 开始颜色 */
        #ffffff 50%,      /* 在中间位置变为另一个颜色 */
        #fff6d1 100%);    /* 结束颜色 */
  padding-bottom: 24px;
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
// 企业荣誉
.first {
  width: calc(100% - 32px - 32px);
  position: relative;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  .firstImg {
    margin: 0 auto;
    max-width: 100%;
    height: 45vw;
    display: block;
    object-fit: contain;
  }
  .secondImg {
    max-width: calc(50% - 4px);
    height: 100%;
    max-height: 35vw;
    display: block;
    object-fit: contain;
    // max-width: calc(50% - 4px);
    // height: 35vw;
    // display: block;
    // object-fit: contain;
  }
}
.topTitle {
  font-weight: bold;
  font-size: 17px;
  color: #d82a1f;
  text-align: center;
  position: relative;
  z-index: 4;
}
.TitleLine {
  width: 68px;
  height: 3px;
  background-color: #d82a1f;
  position: relative;
  margin: 0 auto;
  margin-top: 6px;
  margin-bottom: 19px;
  border-radius: 6px;
}
.TitleLine::before,
.TitleLine::after {
  content: ""; /* 必须指定内容 */
  position: absolute; /* 绝对定位 */
  top: 1.4px; /* 垂直居中 */
  height: 0.1px; /* 细线高度 */
  background-color: #d82a1f; /* 细线颜色 */
}

.TitleLine::before {
  left: -100px; /* 左侧细线的位置 */
  width: 100px; /* 左侧细线的宽度 */
}

.TitleLine::after {
  right: -100px; /* 右侧细线的位置 */
  width: 100px; /* 右侧细线的宽度 */
}
.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  color: #231815;
  text-align: center;
}
.intruduceInfo {
  padding: 12px 21px;
  padding-bottom: 0;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  text-indent: 16px;
  .pargath:not(:last-child) {
    margin-bottom: 12px;
  }
}
.placeAddress {
  margin-top: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 6px;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  padding: 11px;
}
.address {
  height: 237px;
  margin: 0 auto;
  //   border: 1px solid #000;
  border-radius: 6px;
  overflow: hidden;
}
.gpsTopText {
  text-align: center;
  font-weight: 900;
  font-size: 17px;
  color: #d82a1f;
  line-height: 20px;
  margin-bottom: 8px;
}
.addressInfo {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: center;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.businessTagContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 14px;
  .businessTag {
    display: flex;
    justify-content: center;
    // margin-right: 6px;
    img{
      width: 19px; height: 19px; display: block
    }

    .businessTagTitle{

      // background-color: #f6e2c8;
      border:1px solid #ac402f;
      border-left-width:0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 10px;
      color: #ac402f;
      padding: 0 2px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.area {
  border-radius: 6px;
  padding: 9px;

  .areaChid{

  position: relative;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  }
  img{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }

}
.areaimg {
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_red_business.png") no-repeat center /
  //   cover;
  background: url("../../assets/images/project1/areaRedBusiness.png") no-repeat
    center / cover;
}
.areaimgText {
  position: absolute;
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;
  text-align: right;
  div {
    font-size: 12px;
    word-break: break-all;
    color: #231815;
    line-height: 16px;
  }
  .only {
    margin: 8px 0 4px 0;
  }
}

.img {
  height: 195px;
  margin: 0 auto;
  display: flex;
  padding-top: 23px;
  justify-content: space-between;
  img {
    width: 319px;
    height: 100%;
    border-radius: 16px;
    margin: 0 auto;
  }
}
.imgLeftAndRight {
  width: 21px;
  height: 130px;
  margin-top: 24px;
}
.backGround {
  background-image: linear-gradient(to bottom, #c12920, #fd965b);
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  padding-bottom: 16px;
}
.reg {
  position: relative;
  z-index: 2;
  margin-top: 16px;
  // margin-bottom: -8px;
}
.reg2 {
  position: relative;
  z-index: 2;
  margin-top: 16px;
  margin-bottom: -8px;
}
.box2 {
  height: 50px;
  margin: 0 -24px;
  position: relative;
  z-index: 1;
  margin-top: -32px;
  text-align: center;
  line-height: 200px;
  overflow: hidden;
}
.box2::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 85px;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 100% 100% 0 0;
  background-color: #fb9159;
}
.areaContainer {
  background-image: linear-gradient(to bottom, #fb9159, #c22b21);
  // background: url("../../assets/images/project3/index3.png") no-repeat
  //   center / cover;
  margin-top: -24px;
  padding-bottom: 130px;
  margin-bottom: -100px;
  position: relative;
  z-index: 9;
}
.areaToptext {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  line-height: 20px;
}

.clickDownLoad {
  color: #fff;
  margin-top: 8px;
  font-size: 13px;
  text-align: right;
  padding-right: 24px;
  margin-bottom: 7px;
}

</style>